import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { classHandler, isErrorResponse, useQuery } from '../../utils';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getCompetition,
  getUser,
  getSettings,
} from '../../selectors';
import { ApplicationBreakpoint, Category } from '../../types';
import { Title } from '../../components/Title';
import { Modal } from '../../components/Modal';
import { Competition } from '../../types';
import {
  deleteCategory,
  deleteCompetition,
  postCategory,
  putCategory,
  putCompetition,
} from '../../api';
import { CompetitionEditContent } from '../../components/CompetitionEditContent';
import { CategoryEditContent } from '../../components/CategoryEditContent';
import { defaultNewCategory } from '../../constants';
import { FloatingAddButton } from '../../components/FloatingAddButton';
import { setCompetitions } from '../../actions';

import './CompetitionDetailPage.scss';
import { MobileTitle } from '../../components/MobileTItle';
import { ModalType } from '../CompetitionsPage';
import { AlertModal } from '../../components/AlertModal';

enum ModalContent {
  none = 'none',
  editCompetition = 'editCompetition',
  editCategory = 'editCategory',
  newCategory = 'newCategory',
}

export const CompetitionDetailPage: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const competitionId = parseInt(useQuery().get('id') || '0', 0);

  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const competition = useSelector(getCompetition(competitionId));
  const settings = useSelector(getSettings);
  const user = useSelector(getUser);

  const [activeCategoryId, setActiveCategoryId] = useState<number>(0);
  const [changedCompetition, setChangedCompetition] = useState<Competition | null>(competition);
  const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.none);
  const [newCategory, setNewCategory] = useState<Category>(defaultNewCategory);
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);

  useEffect(() => {
    setChangedCompetition(competition);
  }, [competition]);

  useEffect(() => {
    if (user?.id) dispatch(setCompetitions(user.id));
  }, [dispatch, user]);

  if (!changedCompetition) return null;

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const handleNewCategoryChange = (category: Category) => {
    setNewCategory(category);
  };

  const closeModal = () => {
    setModalContent(ModalContent.none);
  };

  const openEditCompetitionModal = () => {
    setModalContent(ModalContent.editCompetition);
  };

  const openNewCategoryModal = () => {
    setModalContent(ModalContent.newCategory);
  };

  const getActiveCategory = () => {
    return changedCompetition?.categories.find(
      (category: Category) => category.id === activeCategoryId
    ) as Category;
  };

  const handleCategoryChange = (changedCategory: Category) => {
    const changedCategories = changedCompetition?.categories.map((category: Category) => {
      if (category.id === activeCategoryId) return changedCategory;
      return category;
    });

    setChangedCompetition({ ...changedCompetition, categories: changedCategories });
  };

  const handleCompetitionChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setChangedCompetition({ ...changedCompetition, [name]: value });
  };

  const saveCompetition = () => {
    const currentSport = settings?.sports.id;
    const currentCountry = settings?.country.id;
    putCompetition(changedCompetition, currentSport, currentCountry).then((response: any) => {
      if (!isErrorResponse(response)) {
        setModalContent(ModalContent.none);

        if (user) dispatch(setCompetitions(user.id));
      }
    });
  };

  const saveCategory = () => {
    putCategory(getActiveCategory()).then((response: any) => {
      if (!isErrorResponse(response)) {
        setModalContent(ModalContent.none);

        if (user) dispatch(setCompetitions(user.id));
      }
    });
    setNewCategory({ ...newCategory, name: '' });
  };

  const saveNewCategory = () => {
    postCategory(newCategory, competition?.id).then((response: any) => {
      if (!isErrorResponse(response)) {
        setModalContent(ModalContent.none);

        if (user) dispatch(setCompetitions(user.id));
      }
    });
    setNewCategory({ ...newCategory, name: '' });
  };

  const removeCategory = () => {
    deleteCategory(getActiveCategory()?.id).then((response: any) => {
      if (!isErrorResponse(response)) {
        setModalContent(ModalContent.none);

        if (user) dispatch(setCompetitions(user.id));
      }
    });
  };

  const removeCompetition = (result: boolean) => {
    handleConfirmVisibilityChange();

    if (result) {
      deleteCompetition(competition?.id).then((response: any) => {
        if (!isErrorResponse(response)) {
          history.push('/competitions');
        }
      });
    }
  };

  const renderModalContent = () => {
    switch (modalContent) {
      case ModalContent.editCompetition: {
        return (
          <CompetitionEditContent
            competition={changedCompetition}
            handleCompetition={handleCompetitionChange}
            handleSave={saveCompetition}
            modalType={ModalType.edit}
          />
        );
      }
      case ModalContent.editCategory: {
        return (
          <CategoryEditContent
            category={getActiveCategory()}
            handleCategory={handleCategoryChange}
            handleSave={saveCategory}
            handleDelete={removeCategory}
            modalType={ModalType.edit}
          />
        );
      }
      case ModalContent.newCategory: {
        return (
          <CategoryEditContent
            category={newCategory}
            handleCategory={handleNewCategoryChange}
            handleSave={saveNewCategory}
            modalType={ModalType.new}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderCategories = changedCompetition?.categories.map((category: Category) => {
    const handleClick = () => {
      setActiveCategoryId(category?.id || 0);
      setModalContent(ModalContent.editCategory);
    };

    return (
      <TableRow hover={true} style={{ cursor: 'pointer' }} key={category.id} onClick={handleClick}>
        <TableCell>{t(category.name)}</TableCell>
        <TableCell align="right">{category.rewards.typeR}</TableCell>
      </TableRow>
    );
  });

  return (
    <div className={classHandler('CompetitionDetailPage', applicationMode)}>
      {applicationBreakpoint === ApplicationBreakpoint.desktop ? (
        <Title
          handleHeadingClick={openEditCompetitionModal}
          backFunction={true}
          title={t(changedCompetition?.name) || ''}
          withIcons={[
            { name: 'edit', onClick: openEditCompetitionModal, visible: true },
            { name: 'trash', onClick: handleConfirmVisibilityChange, visible: true },
          ]}
        />
      ) : (
        <MobileTitle
          title={t(changedCompetition?.name) || ''}
          withIcons={[
            { name: 'edit', onClick: openEditCompetitionModal, visible: true },
            { name: 'trash', onClick: handleConfirmVisibilityChange, visible: true },
          ]}
          backFunction={true}
        />
      )}

      <AlertModal
        isVisible={isConfirmVisible}
        width="fit-content"
        height="fit-content"
        handleClose={removeCompetition}
        confirmBtn={true}
        cancelBtn={true}
        text={t('deleteCompetition')}
        confirmText={t('delete')}
      />

      <div className="CompetitionDetailPage__scrollWrapper">
        <div className="CompetitionDetailPage__contentWrapper">
          <div className="CompetitionDetailPage__container">
            <h3>{t('categories')}</h3>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="CompetitionDetailPage__headerText">{t('name')}</TableCell>
                  <TableCell className="CompetitionDetailPage__headerText" align="right">
                    {t('reward')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderCategories}</TableBody>
            </Table>
          </div>
        </div>
      </div>
      <Modal
        isVisible={modalContent !== ModalContent.none}
        width="500px"
        height="fit-content"
        handleClose={closeModal}
      >
        {renderModalContent()}
      </Modal>
      <FloatingAddButton handleClick={openNewCategoryModal} />
    </div>
  );
};

import LockIcon from '@material-ui/icons/Lock';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setMatchIdForSituations } from '../../actions/recorderSituationsActions';
import { MatchDetail, SaveButtonState } from '../../types';
import { AlertModal } from '../AlertModal';
import { MatchButton } from '../MatchButton';
import useIsNewMatch from '../../utils/useIsNewMatch';
import { getIsPremium } from '../../selectors';
import { setPremiumModalVisibility } from '../../actions';
import { PREMIUM_MODAL_CONTENT_LOC_KEY } from '../DigiRecorderContent/constants';

const DigiRecorderButton: React.FC<{
  match: MatchDetail;
  setSaving: React.Dispatch<React.SetStateAction<SaveButtonState>>;
}> = ({ match }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPremium = useSelector(getIsPremium);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const history = useHistory();
  const isNew = useIsNewMatch();

  const openPremiumModal = () =>
    dispatch(setPremiumModalVisibility(true, PREMIUM_MODAL_CONTENT_LOC_KEY));

  const showConfirmModal = () => {
    if (match.clips.length > 0) {
      setIsConfirmModalVisible(true);
      return;
    }

    handleCloseModal(true);
  };

  const hideConfirmModal = () => {
    setIsConfirmModalVisible(false);
  };

  const handleCloseModal = (result: boolean) => {
    hideConfirmModal();

    if (!result) {
      return;
    }

    if (match.id) {
      dispatch(setMatchIdForSituations(match.id));
      const search = [`id=${match.id}`];
      if (isNew) {
        search.push(`isNew=true`);
      }
      history.push(`digirecorder?${search.join('&')}`);
      return;
    }
  };

  return (
    <span>
      <AlertModal
        isVisible={isConfirmModalVisible}
        width={'max(300px, 90vw)'}
        height={'auto'}
        handleClose={handleCloseModal}
        text={t('confirmOverwriteSituations')}
        confirmBtn={true}
        cancelBtn={true}
      />

      <div className="MatchDetailVideoContent__fileInputWrapper MatchDetailVideoContent__videoSetupPageButtonRow">
        <div className="MatchDetailVideoContent__fileInputLabel">
          <span>{t('recordLiveText')}</span>
          <div className="MatchDetailVideoContent__fileInputDescription">{t('duringTheGame')}</div>
          {!isPremium && (
            <div className="MatchDetailVideoContent__premium" onClick={openPremiumModal}>
              <LockIcon className="MatchDetailVideoContent__premium__icon" />
              {t('premiumFeatureSituations')}
            </div>
          )}
        </div>

        <MatchButton label={t('recordLiveButton')} type="secondary" onClick={showConfirmModal} />
      </div>
    </span>
  );
};

export default DigiRecorderButton;

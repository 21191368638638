import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setClipCategories } from '../../actions/clipCategoriesActions';
import { addRecorderSituation } from '../../actions/recorderSituationsActions';
import { postMatchDetail } from '../../api';
import {
  getApplicationModeState,
  getIsPremium,
  getMatchDetail,
  getSettings,
} from '../../selectors';
import { getRecorderSituations } from '../../selectors/recorderSituations';
import { MatchDetail, TimeStamp } from '../../types';
import { classHandler, setMatchDetailTabToLocalStorage, vibrateDevice } from '../../utils';
import { AlertModal } from '../AlertModal';
import { ContentLoader } from '../ContentLoader';
import DigiRecorderSituations from '../DigiRecorderSituations/DigiRecorderSituations';
import DigiRecorderStopwatch from '../DigiRecorderStopwatch/DigiRecorderStopwatch';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';

import './DigiRecorderContent.scss';
import useIsNewMatch from '../../utils/useIsNewMatch';
import { setPremiumModalVisibility } from '../../actions';
import { ButtonProps } from '../Button';
import { PREMIUM_MODAL_CONTENT_LOC_KEY, SITUATIONS_LIMIT } from './constants';

const DigiRecorderContent = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const [timeMiliseconds, setTimeMiliseconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isConfirmLeaveVisible, setConfirmLeaveVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPremium = useSelector(getIsPremium);
  const settings = useSelector(getSettings);
  const history = useHistory();
  const digirecorder = useSelector(getRecorderSituations);
  const situationsCount = digirecorder.situations.length || 0;
  const [loading, setLoading] = useState(false);
  const match = useSelector(getMatchDetail);
  const isNew = useIsNewMatch();
  const openPremiumModal = useCallback(
    () => dispatch(setPremiumModalVisibility(true, PREMIUM_MODAL_CONTENT_LOC_KEY)),
    [dispatch]
  );

  const freeTierDisabled = !isPremium && situationsCount >= SITUATIONS_LIMIT;

  useEffect(() => {
    if (!isRunning) {
      return;
    }

    let previousTimestamp = Date.now();
    const intervalId = setInterval(() => {
      const timestampNow = Date.now();
      setTimeMiliseconds(prev => prev + (timestampNow - previousTimestamp));
      previousTimestamp = timestampNow;
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  useEffect(() => {
    if (!settings) {
      return;
    }
    dispatch(setClipCategories(settings?.sports.id));
  }, [dispatch, settings]);

  const getTimeSeconds = useCallback(() => {
    return Math.floor(timeMiliseconds / 1000);
  }, [timeMiliseconds]);

  const buttons = useMemo(() => {
    if (!isRunning) {
      return {
        primaryButton: {
          label: t('recordingMatchStart'),
          onClick: () => {
            setIsRunning(true);
            vibrateDevice(100);
          },
        },
      };
    }

    const primaryButton: ButtonProps = {
      label: t('recordingSaveSituation'),
      onClick: () => {
        if (freeTierDisabled) {
          openPremiumModal();
          return;
        }
        dispatch(addRecorderSituation(getTimeSeconds()));
      },
      disabled: freeTierDisabled,
      premiumFeature: true,
    };

    const secondaryButton: ButtonProps = {
      label: t('recordingMatchFinish'),
      onClick: () => {
        setConfirmLeaveVisible(true);
      },
    };

    return { primaryButton, secondaryButton };
  }, [isRunning, t, freeTierDisabled, dispatch, getTimeSeconds, openPremiumModal]);

  const handleLeave = (res: boolean) => {
    setConfirmLeaveVisible(false);
    if (!res) {
      return;
    }

    const transformedClips: TimeStamp[] = digirecorder.situations.map(clip => {
      return {
        id: undefined,
        name: clip.videoName,
        startTime: settings?.defaultVideoStartingTime || 0,
        endTime: settings?.defaultVideoEndingTime || 0,
        time: clip.timeSeconds,
        clipCategories: clip.categories.map(c => c.id),
      };
    });

    setLoading(true);
    postMatchDetail({ ...match, clips: transformedClips } as MatchDetail, settings?.sports.id).then(
      () => {
        setMatchDetailTabToLocalStorage({ id: 1, name: '' });
        const search = [];
        search.push(`id=${match?.id}`);
        if (isNew) {
          search.push('isNew=true');
        }
        history.push(`/match-detail?${search.join('&')}`);
      }
    );
  };

  if (!digirecorder.forMatchId) {
    return (
      <div className={classHandler('DigiRecorderContent', applicationMode)}>
        <div style={{ textAlign: 'center' }} className="DigiRecorderContent__container">
          Invalid Match. (Is the match saved?)
        </div>
      </div>
    );
  }

  return loading ? (
    <ContentLoader />
  ) : (
    <>
      <AlertModal
        isVisible={isConfirmLeaveVisible}
        width={'auto'}
        height={'auto'}
        handleClose={handleLeave}
        text={t('confirmRecordingMatchFinish')}
        confirmBtn={true}
        confirmText={t('YesLeave')}
        cancelBtn={true}
        cancelText={t('NoStay')}
      />
      <div className={classHandler('DigiRecorderContent', applicationMode)}>
        <div className="DigiRecorderContent__container">
          <DigiRecorderStopwatch timeSeconds={getTimeSeconds()} showLabel={!isRunning} />
        </div>
        <div className="DigiRecorderContent__container">
          <DigiRecorderSituations />
        </div>
      </div>
      <SaveButtonsWrapper {...buttons} />
    </>
  );
};

export default DigiRecorderContent;

import LockIcon from '@material-ui/icons/Lock';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setInfoModalText, setInfoModalVisibility, setPremiumModalVisibility } from '../../actions';
import { getApplicationModeState, getIsPremium, getSettings } from '../../selectors';
import { MatchDetail, TimeStamp } from '../../types';
import { isAndroidApp, isIOSApp, useModalKeyboardShortcuts, useNFCHandler } from '../../utils';
import { NoNFCBanner } from '../NoNFCBanner';
import { MatchButton } from '../MatchButton';
import { Modal } from '../Modal';
import './MatchDetailVideoTransferTimestampsModal.scss';
import { AlertModal } from '../AlertModal';

type MatchDetailVideoTransferTimestampsModalProps = {
  newMatch: MatchDetail;
  handleNewMatch: any;
};

export const MatchDetailVideoTransferTimestampsModal: FunctionComponent<MatchDetailVideoTransferTimestampsModalProps> = ({
  newMatch,
  handleNewMatch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const isPremium = useSelector(getIsPremium);
  const iOSHandler = useNFCHandler;
  const applicationMode = useSelector(getApplicationModeState);

  const [noNFCBannerVisible, setNoNFCBannerVisible] = useState<boolean>(false);
  const [isTransferConfirmModalVisible, setIsTransferConfirmModalVisible] = useState<boolean>(
    false
  );

  const handleNoNFCBannerVisibilityChange = () => {
    setNoNFCBannerVisible(!noNFCBannerVisible);
  };

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const triggerSyncTimeFileInput = () => {
    const iOS = (window as any).webkit;
    const android = (window as any).JSBridge;
    const sortByTime = (a: number, b: number) => {
      return a - b;
    };

    if (!iOS && !android) {
      handleNoNFCBannerVisibilityChange();
    }

    if (!iOS) {
      // tslint:disable-next-line
      console.error('Webkit (iOS) not available');
    }
    if (iOS) {
      // iOS.messageHandlers.ReactObserver.postMessage('gimme my timestamps');
      iOSHandler((result: any) => {
        if (result.status === 1) {
          dispatch(setInfoModalText(result.error));
          dispatch(setInfoModalVisibility(true));
        }

        const data = result.data;

        const importedTimeStamps: TimeStamp[] = data
          .sort(sortByTime)
          .map((timeStamp: any, _index: number) => {
            const convertedTime = timeStamp + newMatch.syncTime;

            return {
              name: `Video ${_index + 1}`,
              time: convertedTime,
              startTime: settings?.defaultVideoStartingTime || 0,
              endTime: settings?.defaultVideoEndingTime || 0,
            };
          });
        handleNewMatch({
          ...newMatch,
          clips: importedTimeStamps,
        });
      });
    }

    if (!android) {
      // tslint:disable-next-line
      console.error('JSBridge (Android) not available');
      if (!iOS) handleNoNFCBannerVisibilityChange();
      return;
    }

    if (!android.hasNFC()) {
      handleNoNFCBannerVisibilityChange();
      return;
    }

    if (android.hasNFC()) {
      // @ts-ignore
      window.onTimestamps = (data: number[]) => {
        const importedTimeStamps: TimeStamp[] = data
          .sort(sortByTime)
          .map((timeStamp: any, _index: number) => {
            const convertedTime = timeStamp + newMatch.syncTime;

            return {
              name: `Video ${_index + 1}`,
              time: convertedTime,
              startTime: settings?.defaultVideoStartingTime || 0,
              endTime: settings?.defaultVideoEndingTime || 0,
            };
          });
        handleNewMatch({
          ...newMatch,
          clips: importedTimeStamps,
        });
      };

      android.requestTimestamps();
    }
  };

  const openAndroidApp = () => {
    window.open('https://play.google.com/store/apps/details?id=com.koala42.refrec');
  };

  const openIOSApp = () => {
    window.open('https://apps.apple.com/cz/app/referee-recorder/id1521423589');
  };

  const onTransferClick = () => {
    if (!isIOSApp() && !isAndroidApp()) {
      handleNoNFCBannerVisibilityChange();
      return;
    }

    if (newMatch.clips.length > 0) {
      setIsTransferConfirmModalVisible(true);
      return;
    }

    triggerSyncTimeFileInput();
  };

  const handleCloseTransferConfirmModal = (result: boolean) => {
    if (result) {
      triggerSyncTimeFileInput();
    }
    setIsTransferConfirmModalVisible(false);
  };

  useModalKeyboardShortcuts(
    noNFCBannerVisible,
    handleNoNFCBannerVisibilityChange,
    handleNoNFCBannerVisibilityChange
  );

  return (
    <span>
      <div className="MatchDetailVideoContent__fileInputWrapper MatchDetailVideoContent__videoSetupPageButtonRow">
        <div className="MatchDetailVideoContent__fileInputLabel">
          {t('timeStamps')}
          <div className="MatchDetailVideoContent__fileInputDescription">{t('afterTheGame')}</div>
          {!isPremium && (
            <div className="MatchDetailVideoContent__premium" onClick={openPremiumModal}>
              <LockIcon className="MatchDetailVideoContent__premium__icon" />
              {t('premiumFeatureAvailable')}
            </div>
          )}
        </div>

        <div
          onClick={!isPremium ? openPremiumModal : undefined}
          className="MatchDetailVideoContent__MatchButtonWrapper"
        >
          <MatchButton
            onClick={onTransferClick}
            label={t('transfer')}
            type="secondary"
            iconStyle={{ marginRight: '0.3em' }}
            iconRotation="90deg"
            disabled={!isPremium}
            theme={applicationMode}
          />
        </div>

        {
          <Modal
            isVisible={noNFCBannerVisible}
            width="fit-content"
            height="fit-content"
            handleClose={handleNoNFCBannerVisibilityChange}
          >
            <div>
              <NoNFCBanner handleApple={openIOSApp} handleGoogle={openAndroidApp} />
              <div
                className="MatchDetailVideoContent__modalBtn"
                onClick={handleNoNFCBannerVisibilityChange}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '20px 10px 0 10px',
                }}
              >
                OK
              </div>
            </div>
          </Modal>
        }
        <AlertModal
          isVisible={isTransferConfirmModalVisible}
          width={'max(300px, 90vw)'}
          height={'auto'}
          handleClose={handleCloseTransferConfirmModal}
          text={t('confirmOverwriteSituations')}
          confirmBtn={true}
          cancelBtn={true}
        />
      </div>
    </span>
  );
};

import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationBreakpoint, ClipDetailWithMatch } from '../../types';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getClipCategories,
  getIsPremium,
} from '../../selectors';
import { classHandler, parseTime, isErrorResponse, timePresentation } from '../../utils';
import { TimeInput } from '../TimeInput/TimeInput';
import { InputWrapper } from '../InputWrapper';
import { ClipCategoriesContent } from '../ClipCategoriesContent';
import { TimeStampController } from '../TimeStampController';
import { setPremiumModalVisibility } from '../../actions';
import LockIcon from '@material-ui/icons/Lock';

import './ClipController.scss';
import { deleteClipDetail } from '../../api';
import { AlertModal } from '../AlertModal';

type ClipControllerProps = {
  clipDetail: ClipDetailWithMatch | null;
  goBack: () => void;
  setClipDetail: Dispatch<SetStateAction<ClipDetailWithMatch | null>>;
  handleFavourite?: () => void;
  disableKeyboardShortcut: () => void;
  enableKeyboardShortcut: () => void;
  redirect?: () => void;
  handleShare?: () => void;
  clipShared?: boolean;
  clipSyncTime: number;
  setClipSyncTime: Dispatch<SetStateAction<number>>;
  setForceStopPlaying: Dispatch<SetStateAction<boolean>>;
};

export const ClipController: FunctionComponent<ClipControllerProps> = ({
  clipDetail,
  goBack,
  setClipDetail,
  disableKeyboardShortcut,
  enableKeyboardShortcut,
  clipSyncTime,
}) => {
  const { t } = useTranslation();
  const isPremium = useSelector(getIsPremium);
  const dispatch = useDispatch();
  const [isAlertVisible, setAlertVisible] = useState<boolean>(false);
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);
  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const defaultClipCategories = useSelector(getClipCategories);

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const handleAlertVisibilityChange = () => {
    setAlertVisible(!isAlertVisible);
  };

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  if (!clipDetail) return null;

  const handleChange = (event: any) => {
    const entryName = event.target.name;
    const entryValue = event.target.value;
    switch (entryName) {
      case 'time':
        setClipDetail({ ...clipDetail, time: parseInt(entryValue, 10) || 0 });
        break;
      case 'name':
        setClipDetail({ ...clipDetail, name: entryValue });
        break;
      case 'note':
        setClipDetail({ ...clipDetail, note: entryValue });
        break;
      case 'publicNote':
        setClipDetail({ ...clipDetail, publicNote: entryValue });
        break;
      default:
        break;
    }
  };

  const handleIsPrivate = (event: any) => {
    if (
      clipDetail.isPrivate &&
      clipDetail.clipCategories &&
      clipDetail.clipCategories.length === 0
    ) {
      handleAlertVisibilityChange();
    } else {
      setClipDetail({ ...clipDetail, isPrivate: !event.target.checked });
    }
  };

  const addStartTime = () =>
    setClipDetail({
      ...clipDetail,
      startTime: clipDetail.startTime + 5,
    });
  const subStartTime = () =>
    setClipDetail({
      ...clipDetail,
      startTime: clipDetail.startTime - 5,
    });
  const addEndTime = () =>
    setClipDetail({
      ...clipDetail,
      endTime: clipDetail.endTime + 5,
    });
  const subEndTime = () =>
    setClipDetail({
      ...clipDetail,
      endTime: clipDetail.endTime - 5,
    });

  const buttonsDisabled = clipDetail.endTime - clipDetail.startTime === 5;

  const handleDelete = (result: boolean) => {
    handleConfirmVisibilityChange();
    if (result) {
      deleteClipDetail(clipDetail.id).then((response: any) => {
        if (!isErrorResponse(response)) {
          setTimeout(() => {
            goBack();
          });
        }
      });
    }
  };

  return (
    <div className={classHandler('ClipController', applicationMode)}>
      <AlertModal
        isVisible={isAlertVisible}
        width="fit-content"
        height="fit-content"
        handleClose={handleAlertVisibilityChange}
        confirmBtn={true}
        cancelBtn={false}
        text={t('categoryBeforeShare')}
      />

      <AlertModal
        isVisible={isConfirmVisible}
        width="fit-content"
        height="fit-content"
        handleClose={handleDelete}
        confirmBtn={true}
        cancelBtn={true}
        text={t('deleteClip')}
        confirmText={t('delete')}
      />

      <div
        style={{ marginBottom: '1.5em' }}
        className={classHandler('ClipController__container', applicationMode)}
      >
        <div className="ClipController__head">
          <h4>{t('clipDetail')}</h4>
        </div>

        <InputWrapper spacing="30px 0" iconName="pen">
          <TextField
            style={{ width: '100%' }}
            value={clipDetail.name}
            name="name"
            label={t('clipName')}
            onChange={handleChange}
            autoComplete="off"
            onFocus={disableKeyboardShortcut}
            onBlur={enableKeyboardShortcut}
          />
        </InputWrapper>
        <InputWrapper spacing="30px 0 10px 0" iconName="clock">
          <div className={classHandler('ClipController__timeInputDescription', applicationMode)}>
            <div>{t('timeInOrigVideo')}</div>
            {clipSyncTime !== 0 ? (
              <>
                <div>
                  {t('resynchronized')}
                  {': '}
                  {clipSyncTime > 0
                    ? ` +${timePresentation(clipSyncTime, false, true)}`
                    : ` -${timePresentation(-clipSyncTime, false, true)}`}
                </div>
              </>
            ) : null}
          </div>

          <TimeInput
            time={clipDetail.time + clipSyncTime - clipDetail.syncTime}
            handleChange={handleChange}
          />
        </InputWrapper>
        <div className="ClipController__timeStampsControllers">
          <TimeStampController
            label={t('start')}
            time={parseTime(clipDetail.startTime)}
            handlePlus={addStartTime}
            handleMinus={subStartTime}
            plusDisabled={buttonsDisabled}
            minusDisabled={false}
          />
          <TimeStampController
            label={t('end')}
            time={parseTime(clipDetail.endTime)}
            handlePlus={addEndTime}
            handleMinus={subEndTime}
            plusDisabled={false}
            minusDisabled={buttonsDisabled}
          />
        </div>
      </div>

      {applicationBreakpoint !== ApplicationBreakpoint.desktop && (
        <>
          <div
            style={{ marginBottom: '1.5em' }}
            className={classHandler('ClipController__container', applicationMode)}
          >
            <div className="ClipController__head">
              <h4>{t('clipCategories')}</h4>
            </div>
            <ClipCategoriesContent
              newClipDetail={clipDetail}
              handleNewClipDetail={setClipDetail}
              defaultClipCategories={defaultClipCategories}
            />
          </div>
          <div
            style={{ marginBottom: '1.5em' }}
            className={classHandler('ClipController__container', applicationMode)}
          >
            <div className="ClipController__head">
              <h4>{t('shareCommunity')}</h4>
            </div>
            <React.Fragment>
              <InputWrapper spacing="30px 0" iconName="share">
                <div className="ClipController__switchWrapper" style={{ flexWrap: 'wrap' }}>
                  <div className="ClipController__shareLabel">{t('shareCommunity')}</div>
                  <div onClick={!isPremium ? openPremiumModal : undefined}>
                    <Switch
                      checked={!clipDetail.isPrivate}
                      name="isPrivate"
                      onChange={handleIsPrivate}
                      color="primary"
                      disabled={!isPremium}
                    />
                  </div>

                  {!isPremium && (
                    <div className="ClipSubController__premium" onClick={openPremiumModal}>
                      <LockIcon className="ClipSubController__premium__icon" />
                      {t('premiumFeatureAvailable')}
                    </div>
                  )}
                </div>
              </InputWrapper>
            </React.Fragment>
          </div>

          <div
            style={{ marginBottom: '1.5em' }}
            className={classHandler('ClipController__container', applicationMode)}
          >
            <div className="ClipController__head">
              <h4>{t('clipNotePublic')}</h4>
            </div>
            <InputWrapper spacing="30px 0" iconName="pen">
              <TextField
                style={{ width: '100%' }}
                value={clipDetail.publicNote}
                name="publicNote"
                label={t('addNotes')}
                onChange={handleChange}
                autoComplete="off"
                onFocus={disableKeyboardShortcut}
                onBlur={enableKeyboardShortcut}
                multiline
              />
            </InputWrapper>
          </div>

          <div className={classHandler('ClipController__container', applicationMode)}>
            <div className="ClipController__head">
              <h4>{t('myPrivateNotes')}</h4>
            </div>
            <InputWrapper spacing="30px 0" iconName="pen">
              <TextField
                style={{ width: '100%' }}
                value={clipDetail.note}
                name="note"
                label={t('addNotes')}
                onChange={handleChange}
                autoComplete="off"
                onFocus={disableKeyboardShortcut}
                onBlur={enableKeyboardShortcut}
                multiline
              />
            </InputWrapper>
          </div>
        </>
      )}

      {applicationBreakpoint === ApplicationBreakpoint.desktop && (
        <div className={classHandler('ClipController__container', applicationMode)}>
          <div className="ClipController__head">
            <h4>{t('clipCategories')}</h4>
          </div>

          <ClipCategoriesContent
            newClipDetail={clipDetail}
            handleNewClipDetail={setClipDetail}
            defaultClipCategories={defaultClipCategories}
          />
        </div>
      )}
    </div>
  );
};

import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, TextField } from '@material-ui/core';

import { ClipDetailWithMatch } from '../../types';
import { classHandler } from '../../utils';
import { getApplicationModeState, getIsPremium } from '../../selectors';
import { InputWrapper } from '../InputWrapper';

import './ClipSubController.scss';
import { AlertModal } from '../AlertModal';
import { setPremiumModalVisibility } from '../../actions';
import LockIcon from '@material-ui/icons/Lock';

type ClipSubController = {
  clipDetail: ClipDetailWithMatch | null;
  setClipDetail: Dispatch<SetStateAction<ClipDetailWithMatch | null>>;
  disableKeyboardShortcut: () => void;
  enableKeyboardShortcut: () => void;
  isClosedCommunityShared: boolean;
  setIsClosedCommunityShared: (isShared: boolean) => void;
};

export const ClipSubController: FunctionComponent<ClipSubController> = ({
  clipDetail,
  setClipDetail,
  disableKeyboardShortcut,
  enableKeyboardShortcut,
  isClosedCommunityShared,
  setIsClosedCommunityShared,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationMode = useSelector(getApplicationModeState);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const isPremium = useSelector(getIsPremium);

  if (!clipDetail) return null;

  const openPremiumModal = () => dispatch(setPremiumModalVisibility(true));

  const handleModalVisibilityChange = () => {
    setModalVisible(!isModalVisible);
  };

  const handleIsPrivate = (event: any) => {
    if (
      clipDetail.isPrivate &&
      clipDetail.clipCategories &&
      clipDetail.clipCategories.length === 0
    ) {
      handleModalVisibilityChange();
    } else {
      setClipDetail({ ...clipDetail, isPrivate: !event.target.checked });
    }
  };

  const handleClosedCommunityShared = (event: any) => {
    if (clipDetail.clipCategories && clipDetail.clipCategories.length === 0) {
      handleModalVisibilityChange();
    } else {
      setIsClosedCommunityShared(event.target.checked);
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;

    setClipDetail({ ...clipDetail, [name]: value });
  };

  return (
    <div className={classHandler('ClipController', applicationMode)}>
      <AlertModal
        isVisible={isModalVisible}
        width="fit-content"
        height="fit-content"
        handleClose={handleModalVisibilityChange}
        confirmBtn={true}
        cancelBtn={false}
        text={t('categoryBeforeShare')}
      />

      <div className={classHandler('ClipController__container', applicationMode)}>
        <div className="ClipController__head">
          <h4>{t('shareCommunity')}</h4>
        </div>
        <InputWrapper iconName="share">
          <div className="ClipController__switchWrapper">
            <div className="ClipController__shareLabel">{t('shareVideo')}</div>
            <div onClick={!isPremium ? openPremiumModal : undefined}>
              <Switch
                checked={!clipDetail.isPrivate}
                name="isPrivate"
                onChange={handleIsPrivate}
                color="primary"
                disabled={!isPremium}
              />
            </div>
          </div>
          {!isPremium && (
            <div className="ClipSubController__premium" onClick={openPremiumModal}>
              <LockIcon className="ClipSubController__premium__icon" />
              {t('premiumFeatureAvailable')}
            </div>
          )}
        </InputWrapper>
        {clipDetail.closedCommunityName && (
          <InputWrapper iconName="share">
            <div className="ClipController__switchWrapper">
              <div className="ClipController__shareLabel">{clipDetail.closedCommunityName}</div>
              <div onClick={!isPremium ? openPremiumModal : undefined}>
                <Switch
                  checked={isClosedCommunityShared}
                  name="closedCommunityShare"
                  onChange={handleClosedCommunityShared}
                  color="primary"
                  disabled={!isPremium}
                />
              </div>
            </div>
            {!isPremium && (
              <div className="ClipSubController__premium" onClick={openPremiumModal}>
                <LockIcon className="ClipSubController__premium__icon" />
                {t('premiumFeatureAvailable')}
              </div>
            )}
          </InputWrapper>
        )}
      </div>

      <div className={classHandler('ClipController__container', applicationMode)}>
        <div className="ClipController__head">
          <h4>{t('clipNotePublic')}</h4>
        </div>
        <InputWrapper iconName="comment">
          <TextField
            style={{ width: '100%' }}
            value={clipDetail.publicNote}
            name="publicNote"
            multiline
            variant="outlined"
            label={t('addNotes')}
            onChange={handleChange}
            autoComplete="off"
            onFocus={disableKeyboardShortcut}
            onBlur={enableKeyboardShortcut}
          />
        </InputWrapper>
      </div>
      <div className={classHandler('ClipController__container', applicationMode)}>
        <div className="ClipController__head">
          <h4>{t('myPrivateNotes')}</h4>
        </div>
        <InputWrapper iconName="comment">
          <TextField
            style={{ width: '100%' }}
            value={clipDetail.note}
            name="note"
            multiline
            variant="outlined"
            label={t('addNotes')}
            onChange={handleChange}
            autoComplete="off"
            onFocus={disableKeyboardShortcut}
            onBlur={enableKeyboardShortcut}
          />
        </InputWrapper>
      </div>
    </div>
  );
};

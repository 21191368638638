import React, { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Category } from '../../types';
import { TextField } from '@material-ui/core';
import { ModalType } from '../../containers/CompetitionsPage';
import { SaveButtonsWrapper } from '../SaveButtonsWrapper';
import { AlertModal } from '../AlertModal';

type CategoryEditContentProps = {
  category: Category;
  handleCategory: any;
  handleSave: any;
  modalType: ModalType;
  handleDelete?: any;
};

export const CategoryEditContent: FunctionComponent<CategoryEditContentProps> = ({
  category,
  handleCategory,
  handleSave,
  modalType,
  handleDelete,
}) => {
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false);

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    const changedCategory = { ...category, name };

    handleCategory(changedCategory);
  };

  const handleConfirmVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const onChangeReward = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rewardType = event.target.name;
    const rewardValue = event.target.value;

    const changedCategory = {
      ...category,
      rewards: {
        ...category.rewards,
        [rewardType]: rewardValue,
      },
    };

    handleCategory(changedCategory);
  };

  const checkDelete = (result: boolean) => {
    setConfirmVisible(false);

    if (result) {
      handleDelete();
    }
  };

  const { t } = useTranslation();
  return (
    <Fragment>
      <AlertModal
        isVisible={isConfirmVisible}
        width="fit-content"
        height="fit-content"
        handleClose={checkDelete}
        confirmBtn={true}
        cancelBtn={true}
        text={t('deleteCategory')}
        confirmText={t('delete')}
      />
      <div className="CompetitionsPage__modalHeader">
        {modalType === ModalType.new
          ? t('createCompetitionCategory')
          : t('editCompetitionCategory')}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 20px',
        }}
      >
        <TextField
          name="name"
          label={t('catName')}
          value={category?.name}
          onChange={onChangeName}
          style={{ margin: '10px 0', width: '100%' }}
          autoComplete="off"
        />
        <TextField
          name="typeR"
          label={t('reward')}
          value={category?.rewards.typeR === null ? '' : category?.rewards.typeR}
          onChange={onChangeReward}
          style={{ margin: '10px 0', width: '100%' }}
          autoComplete="off"
        />
      </div>
      <SaveButtonsWrapper
        primaryButton={{
          label: t('save'),
          onClick: handleSave,
        }}
        secondaryButton={{
          label: t('delete'),
          onClick: handleConfirmVisibilityChange /* handleDelete */,
          hidden: !handleDelete,
        }}
        style={{ paddingBottom: '1em', borderTop: 'none' }}
      />
    </Fragment>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
  clearRecorderSituations,
  setMatchIdForSituations,
} from '../../actions/recorderSituationsActions';
import { AlertModal } from '../../components/AlertModal';
import DigiRecorderContent from '../../components/DigiRecorderContent/DigiRecorderContent';
import { MobileTitle } from '../../components/MobileTItle';
import { Title } from '../../components/Title';
import {
  getApplicationBreakpointState,
  getApplicationModeState,
  getMatchDetail,
} from '../../selectors';
import { getRecorderSituations } from '../../selectors/recorderSituations';
import { ApplicationBreakpoint } from '../../types';
import { classHandler, setMatchDetailTabToLocalStorage } from '../../utils';

import './DigiRecorderPage.scss';
import useIsNewMatch from '../../utils/useIsNewMatch';

const DigiRecorderPage = () => {
  const applicationMode = useSelector(getApplicationModeState);
  const applicationBreakpoint = useSelector(getApplicationBreakpointState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmLeaveVisible, setConfirmLeaveVisible] = useState<boolean>(false);
  const history = useHistory();
  const { forMatchId } = useSelector(getRecorderSituations);
  const match = useSelector(getMatchDetail);
  const isNew = useIsNewMatch();

  useEffect(() => {
    dispatch(clearRecorderSituations());

    return () => {
      dispatch(setMatchIdForSituations(null));
    };
  }, [dispatch]);

  const title = useMemo(() => {
    const heading = t('recordingMatchHeadline');
    const props = {
      title: heading,
      backFunction: true,
      withPrevTab: true,
      goBackTab: () => {
        setConfirmLeaveVisible(true);
      },
    };

    let Component;
    switch (applicationBreakpoint) {
      case ApplicationBreakpoint.desktop:
        Component = Title;
        break;
      default:
        Component = MobileTitle;
        break;
    }
    return <Component {...props} />;
  }, [applicationBreakpoint, t]);

  return (
    <>
      {!forMatchId && <Redirect to="/" />}

      <AlertModal
        isVisible={isConfirmLeaveVisible}
        width={'auto'}
        height={'auto'}
        handleClose={(res: boolean) => {
          setConfirmLeaveVisible(false);
          if (res) {
            setMatchDetailTabToLocalStorage({ id: 1, name: '' });
            history.push(`/match-detail?id=${match?.id}&isNew=${isNew}`);
          }
        }}
        text={t('sureToLeave')}
        confirmBtn={true}
        confirmText={t('YesLeave')}
        cancelBtn={true}
        cancelText={t('NoStay')}
      />
      <div className={classHandler('DigiRecorderPage', applicationMode)}>
        {title}
        <DigiRecorderContent />
      </div>
    </>
  );
};

export default DigiRecorderPage;
